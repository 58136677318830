import styles from "./FabContact.module.scss"
import React from "react"
import cx from "clsx"
import Portal from "@reach/portal"
import { useRouter } from "next/router"

import ChatWhatsapp from "../ChatWhatsapp/ChatWhatsapp"

import WhatsappIcon from "../icons/WhatsappIcon"

const FabContact = ({ children, className, ...restProps }) => {
  const router = useRouter()

  const handleClick = () => {
    // router.push("/contact-us")
  }

  return (
    <>
      <Portal {...restProps}>
        <ChatWhatsapp phone="+6285784919890" message="Hi, Talent Indonesia 👋🏻">
          <button className={styles.contact} onClick={handleClick}>
            <WhatsappIcon className={styles.icon} />
            <span className={styles.label}>Bantuan</span>
          </button>
        </ChatWhatsapp>
      </Portal>
    </>
  )
}

export default FabContact
