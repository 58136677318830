import styles from "./Bell.module.scss"
import React, { useEffect, useRef, useState } from "react"
import cx from "clsx"
import { useAtomValue } from "jotai"

import {
  hasUnreadNotificationsAtom,
  useNotificationsQuery,
} from "../../../atoms/notificationAtom"

import { useClickOutside } from "../../../hooks"

import NotificationFilledIcon from "../../icons/NotificationFilledIcon"
import NotificationNoneIcon from "../../icons/NotificationNoneIcon"

import PopperWrapper from "../../PopperWrapper/PopperWrapper"
import Text from "../../Text/Text"
import Item from "../Item/Item"
import { useWindowSize } from "@reach/window-size"
import { useRouter } from "next/router"

const Trigger = React.forwardRef(({ setShow }, ref) => {
  const router = useRouter()
  const hasUnread = useAtomValue(hasUnreadNotificationsAtom)

  const { width } = useWindowSize()

  return (
    <button
      ref={ref}
      className={cx(styles.trigger, { [styles.marked]: hasUnread })}
      onClick={
        width < 768
          ? () =>
              router.push("/dashboard/notification", undefined, {
                shallow: true,
                scroll: true,
              })
          : () => setShow(true)
      }
    >
      {hasUnread ? (
        <NotificationFilledIcon className={styles.filled} />
      ) : (
        <NotificationNoneIcon className={styles.none} />
      )}
    </button>
  )
})

const List = () => {
  const { data: notifications } = useNotificationsQuery()

  return (
    <ul className={styles.list}>
      {notifications?.map((notification) => (
        <Item key={notification?._id} notification={notification} />
      ))}
    </ul>
  )
}

const Area = React.forwardRef((props, ref) => {
  return (
    <main ref={ref} className={styles.area}>
      <header className={styles.header}>
        <Text heading4 as="h3">
          Notifikasi
        </Text>
      </header>
      <List />
    </main>
  )
})

const Bell = () => {
  const [show, setShow] = useState(false)
  const [triggerRef, setTriggerRef] = useState(null)
  const [wrapperRef, setWrapperRef] = useState(null)

  let areaRef = useRef(null)
  useClickOutside(areaRef, () => {
    setShow(false)
  })

  return (
    <PopperWrapper
      show={show}
      triggerRef={triggerRef}
      setWrapper={setWrapperRef}
      wrapper={wrapperRef}
      placement="bottom-end"
      trigger={<Trigger ref={setTriggerRef} setShow={setShow} />}
    >
      <Area ref={areaRef} />
    </PopperWrapper>
  )
}

export default Bell
