import styles from "./Item.module.scss"
import React from "react"
import cx from "clsx"

import { event } from "../../../utils/event"

import Text from "../../Text/Text"
import Link from "../../Link/Link"
import { formatDistanceToNowStrict } from "date-fns"
import { id } from "date-fns/locale"
import AvatarAlt from "../../AvatarAlt/AvatarAlt"
import { useSetAtom } from "jotai"
import { readNotificationAtom } from "../../../atoms/notificationAtom"
import ResumeVerifiedIcon from "../../icons/ResumeVerifiedIcon"
import ResumeRejectedIcon from "../../icons/ResumeRejectedIcon"

const ResumeVerified = ({ notification }) => {
  const Wrapper = notification?.candidate ? Link : "div"
  const to = `/dashboard/resume`

  const readNotification = useSetAtom(readNotificationAtom)

  const handleRead = async () => {
    await readNotification({ notificationId: notification?._id })
  }

  return (
    <li className={styles.root}>
      <Wrapper to={to} className={styles.wrapper} onClick={handleRead}>
        <div className={styles.iconWrapper}>
          <ResumeVerifiedIcon className={cx(styles.icon, styles.verified)} />
        </div>
        <div className={styles.main}>
          <Text
            small
            as="p"
            className={cx(styles.text, {
              [styles.unread]: !notification?.isRead,
            })}
          >
            <strong>Resume berhasil diverifikasi!</strong>
          </Text>
          <Text as="p" caption className={styles.timestamp}>
            {formatDistanceToNowStrict(new Date(notification?.created_at), {
              addSuffix: true,
              roundingMethod: "floor",
              locale: id,
            })}
          </Text>
        </div>
        <aside className={styles.aside}>
          {!notification?.isRead && notification?.candidate && (
            <div className={styles.marker} />
          )}
        </aside>
      </Wrapper>
    </li>
  )
}

const ResumeRejected = ({ notification }) => {
  const Wrapper = notification?.candidate ? Link : "div"
  const to = `/dashboard/resume`

  const readNotification = useSetAtom(readNotificationAtom)

  const handleRead = async () => {
    await readNotification({ notificationId: notification?._id })
  }

  return (
    <li className={styles.root}>
      <Wrapper to={to} className={styles.wrapper} onClick={handleRead}>
        <div className={styles.iconWrapper}>
          <ResumeRejectedIcon className={cx(styles.icon, styles.rejected)} />
        </div>
        <div className={styles.main}>
          <Text
            small
            as="p"
            className={cx(styles.text, {
              [styles.unread]: !notification?.isRead,
            })}
          >
            <strong>Verifikasi resume gagal!</strong> Perbarui data resume kamu
            dan silakan coba lagi.
          </Text>
          <Text as="p" caption className={styles.timestamp}>
            {formatDistanceToNowStrict(new Date(notification?.created_at), {
              addSuffix: true,
              roundingMethod: "floor",
              locale: id,
            })}
          </Text>
        </div>
        <aside className={styles.aside}>
          {!notification?.isRead && notification?.candidate && (
            <div className={styles.marker} />
          )}
        </aside>
      </Wrapper>
    </li>
  )
}

const Item = ({ notification, className, ...restProps }) => {
  switch (notification.category) {
    case event.resume.verified:
      return <ResumeVerified notification={notification} />
    case event.resume.rejected:
      return <ResumeRejected notification={notification} />

    default:
      return <p>Default</p>
  }
}

export default Item
