import styles from "./Drawer.module.scss"
import React, { useState } from "react"
import cx from "clsx"

import { useWindowSize } from "@reach/window-size"
import { DialogOverlay, DialogContent } from "@reach/dialog"
import { motion, AnimatePresence } from "framer-motion"

const Drawer = ({
  identifier,
  children,
  full,
  onDismiss,
  ariaLabel,
  overlayClassName,
  contentClassName,
  show,
  setShow,
  onDragEnd,
  withHandler,
}) => {
  const { width: viewportWidth } = useWindowSize()

  const [startPoint, setStartPoint] = useState(null)

  const handleDragEnd = (event) => {
    onDragEnd && onDragEnd()
    if (startPoint && event.y - startPoint >= 100) {
      setShow(false)
    }
  }

  return (
    <AnimatePresence initial={false}>
      {show && (
        <DialogOverlay
          key={identifier ?? "drawer-identifier"}
          as={motion.div}
          isOpen={show}
          onDismiss={() => {
            onDismiss && typeof onDismiss === "function"
              ? onDismiss()
              : setShow && setShow(false)
          }}
          className={cx(styles.root, { [overlayClassName]: overlayClassName })}
        >
          <DialogContent
            as={motion.div}
            layout
            drag={viewportWidth < 951 ? "y" : false}
            dragDirectionLock
            dragElastic={0.25}
            dragConstraints={{ top: 0, bottom: 50 }}
            className={cx(styles.content, {
              [contentClassName]: contentClassName,
              [styles.full]: full,
              [styles.shown]: show,
              [styles.hidden]: !show,
            })}
            aria-label={ariaLabel ?? "dialog content"}
            initial={{ opacity: 0, y: "110%" }}
            animate={{ opacity: 1, y: "0%" }}
            transition={{
              duration: viewportWidth < 951 ? 0.24 : 0.124,
              ease: "easeOut",
            }}
            exit={{ y: "110%", opacity: viewportWidth < 951 ? 1 : 0 }}
            onDragStart={(event) => setStartPoint(event?.y)}
            onDragEnd={handleDragEnd}
            dragSnapToOrigin={true}
          >
            {(!full || withHandler) && (
              <button type="button" className={styles.handler}></button>
            )}
            {children}
          </DialogContent>
        </DialogOverlay>
      )}
    </AnimatePresence>
  )
}

export default Drawer
