import styles from "./Spacer.module.scss"
import React from "react"
import cx from "clsx"

const Spacer = ({ space, x, y = !x, ...restProps }) => {
  return (
    <div
      aria-hidden
      className={cx(styles[space], { [styles.x]: x, [styles.y]: y })}
      {...restProps}
    />
  )
}

export default Spacer
