import styles from "./MobileMenu.module.scss"
import React from "react"
import cx from "clsx"

import HomeFilledIcon from "../icons/HomeFilledIcon"

import Link from "../Link/Link"
import BoardIcon from "../icons/BoardIcon"
import LowonganIcon from "../icons/LowonganIcon"
import MoreHorizontalIcon from "../icons/MoreHorizontalIcon"

import AccountIcon from "../icons/AccountIcon"
import Sheet from "../Sheet/Sheet"
import { useRouter } from "next/router"
import Text from "../Text/Text"
import BrandInstagramIcon from "../icons/BrandInstagramIcon"
import BrandFacebookIcon from "../icons/BrandFacebookIcon"
import { useUserQuery } from "../../atoms/userAtom"
import AvatarAlt from "../AvatarAlt/AvatarAlt"
import ChevronRight from "../icons/ChevronRight"
import Spacer from "../../layouts/Spacer/Spacer"
import { useSession } from "next-auth/react"

const UserCard = ({ user }) => {
  return (
    <div className={styles.user}>
      <AvatarAlt src={user?.photo} large />
      <br />
      <Text as="p" heading4 truncate={2}>
        {user?.name}
      </Text>
      <Text as="p" small secondary>
        {user?.verified === "0" && "Resume belum diajukan"}
        {user?.verified === "1" && "Resume sedang diverifikasi"}
        {user?.verified === "2" && "Resume terverifikasi"}
        {user?.verified === "99" && "verifikasi resume ditolak"}
      </Text>
    </div>
  )
}

const Sidebar = ({ toggleAccountSheet }) => {
  const router = useRouter()

  const { data: session } = useSession()
  const { data: user } = useUserQuery()

  return (
    <Sheet
      show={router?.query?.sheet === "more"}
      setShow={() => toggleAccountSheet(false)}
      full
    >
      <ul className={styles.list}>
        {user && <UserCard user={user} />}
        {session && (
          <>
            <MenuLine to="/dashboard/bookmark" label="Lowongan tersimpan" />
            <MenuLine to="/dashboard/lamaran" label="Lamaran" />
          </>
        )}
        <br />
        <Text heading5 as="li">
          Tentang SSW
        </Text>
        <MenuLine to="/tentang-visa-ssw" label="Apa itu Visa SSW?" />
        <MenuLine to="/info-ujian-ssw" label="Info Jadwal Ujian" />
        <br />
        <div className={styles.banner}>
          <Text heading5 as="h3">
            Belum punya sertifikat SSW (Tokutei Ginou)?
          </Text>
          <Text small as="p">
            Ada Program Pelatihan Kerja Lengkap yang akan membimbingmu untuk
            siap melamar pekerjaan impianmu!
          </Text>
          <Link to="/pelatihan-kerja" className={styles.cta}>
            <Text heading6>Selengkapnya tentang pelatihan kerja</Text>
            <ChevronRight className={styles.chevron} />
          </Link>
        </div>
        <br />
        <Text heading5 as="li">
          Perusahaan
        </Text>
        <MenuLine to="/tentang-kami" label="Tentang Kami" />
        <br />
        <br />
        <aside className={styles.menuList}>
          <Text small as="p">
            Terhubung dengan kami melalui social media
          </Text>
          <ul className={styles.socialList}>
            <a
              href="https://www.instagram.com/talentid.info/"
              target="_blank"
              className={styles.social}
            >
              <BrandInstagramIcon className={styles.socialIcon} />
            </a>
            <a
              href="https://www.facebook.com/talentindonesiajaya/"
              target="_blank"
              className={styles.social}
            >
              <BrandFacebookIcon className={styles.socialIcon} />
            </a>
          </ul>
        </aside>
        <br />
        <br />
        <Text as="p" small className={styles.copy}>
          &copy; 2022 Talent Indonesia. All rights reserved
        </Text>
      </ul>
      <Spacer space="xxl" />
    </Sheet>
  )
}

const Menu = ({ to, icon, label, onClick, slug }) => {
  const router = useRouter()
  let menuRegex = new RegExp(`^\\${slug || to}((\\?|\\/).*)?$`)

  return (
    <li
      className={cx(styles.menu, {
        [styles.active]: router.asPath.match(menuRegex),
      })}
    >
      {onClick ? (
        <button className={styles.link} onClick={onClick}>
          {icon}
          {label && <span className={styles.label}>{label}</span>}
        </button>
      ) : (
        <Link to={to} className={styles.link}>
          {icon}
          {label && <span className={styles.label}>{label}</span>}
        </Link>
      )}
    </li>
  )
}

const MenuLine = ({ to, label }) => {
  const router = useRouter()
  let menuRegex = new RegExp(`^\\${to}((\\?|\\/).*)?$`)

  return (
    <li
      className={cx(styles.menuLine, {
        [styles.active]: router.asPath.match(menuRegex),
      })}
    >
      <Link to={to} className={styles.menuLink}>
        {label}
      </Link>
    </li>
  )
}

const MobileMenu = ({ ...restProps }) => {
  const router = useRouter()

  const { data: session } = useSession()

  const toggleAccountSheet = (show, type) => {
    let query = router?.query

    if (show) {
      query.sheet = type
    } else {
      if (type) {
        delete query[type]
      } else {
        delete query["sheet"]
      }
    }

    router.push(
      {
        pathname: router?.pathname,
        query: query,
      },
      undefined,
      { shallow: true, scroll: false }
    )
  }

  return (
    <nav className={cx(styles.root)} {...restProps}>
      <ul className={styles.wrapper}>
        <Menu
          to="/"
          slug="/"
          label={"Home"}
          icon={<HomeFilledIcon className={styles.icon} />}
        />
        <Menu
          to="/lowongan"
          slug="/lowongan"
          icon={<LowonganIcon className={styles.icon} />}
          label="Lowongan"
        />
        {session && (
          <Menu
            to="/dashboard/home"
            slug="/dashboard/home"
            icon={<BoardIcon className={styles.icon} />}
            label="Dashboard"
          />
        )}
        <Menu
          to={session?.token ? "/dashboard/resume" : "/login"}
          icon={<AccountIcon className={styles.icon} />}
          label={session?.token ? "Resume" : "Akun"}
          slug={session?.token ? "/dashboard/resume" : "/login"}
        />
        <Menu
          onClick={() => toggleAccountSheet(true, "more")}
          icon={<MoreHorizontalIcon className={styles.icon} />}
          label="Menu"
          slug="/?sheet=more"
        />
      </ul>
      <Sidebar toggleAccountSheet={toggleAccountSheet} />
    </nav>
  )
}

export default MobileMenu
