import styles from './AvatarAlt.module.scss'
import React from 'react'
import cx from 'clsx'

const AvatarAlt = ({
  src,
  xsmall,
  small,
  medium,
  large,
  xlarge,
  xxlarge,
  huge,
  className,
  contain,
  square,
  ...restProps
}) => {
  return (
    <picture
      className={cx({
        [styles.root]: true,
        [styles.small]: small,
        [styles.medium]: medium,
        [styles.large]: large,
        [styles.xsmall]: xsmall,
        [styles.xlarge]: xlarge,
        [styles.xxlarge]: xxlarge,
        [styles.huge]: huge,
        [styles.square]: square,
        [className]: className,
      })}
      {...restProps}
    >
      <img
        src={src}
        className={cx(styles.photo, { [styles.contain]: contain })}
      />
    </picture>
  )
}

export default AvatarAlt
