import styles from "./Sheet.module.scss"
import React from "react"
import cx from "clsx"

import Drawer from "../Drawer/Drawer"
import CloseIcon from "../icons/CloseIcon"
import Text from "../Text/Text"

const Sheet = ({
  show,
  setShow,
  children,
  title,
  subtitle,
  action,
  footer,
  className,
  full,
  ...restProps
}) => {
  return (
    <Drawer show={show} setShow={setShow} full={full}>
      {(title || subtitle) && (
        <nav className={cx(styles.navbar)}>
          <button
            onClick={() => setShow && setShow(false)}
            className={styles.close}
            aria-label="Close sheet"
          >
            <CloseIcon className={styles.icon} />
          </button>
          <header className={styles.header}>
            {title && (
              <Text as="h2" heading3 truncate={2}>
                {title}
              </Text>
            )}
            {subtitle && (
              <Text small color="secondary" as="p">
                {subtitle}
              </Text>
            )}
          </header>
        </nav>
      )}
      {children && <main className={styles.main}>{children}</main>}
      {footer && <footer className={styles.footer}>{footer}</footer>}
    </Drawer>
  )
}

export default Sheet
