import styles from "./AvatarNavbar.module.scss"
import React, { useRef, useState } from "react"
import cx from "clsx"
import { signOut } from "next-auth/react"

import ChevronLeft from "../icons/ChevronLeft"
import PopperWrapper from "../PopperWrapper/PopperWrapper"
import Link from "../Link/Link"
import { useClickOutside } from "../../hooks"
import FaceIcon from "../icons/FaceIcon"
import { fetchUser, userKeys } from "../../atoms/userAtom"
import { useQuery } from "@tanstack/react-query"

const Menu = ({ button, label, icon, to, cta }) => {
  return (
    <li className={styles.menuWrapper}>
      {button ? (
        <button className={styles.menu} onClick={cta}>
          <span className={styles.label}>{label}</span>
          {icon}
        </button>
      ) : (
        <Link className={styles.menu} to={to}>
          <span className={styles.label}>{label}</span>
          {icon}
        </Link>
      )}
    </li>
  )
}

const handleSignOut = async () =>
  await signOut({ redirect: true, callbackUrl: "/" })

const AvatarNavbar = () => {
  const { data: user } = useQuery(userKeys.profile, fetchUser)

  const [show, setShow] = useState(false)
  const [triggerRef, setTriggerRef] = useState(null)
  const [wrapperRef, setWrapperRef] = useState(null)

  let areaRef = useRef(null)
  useClickOutside(areaRef, () => {
    setShow(false)
  })

  return (
    <PopperWrapper
      show={show}
      triggerRef={triggerRef}
      setWrapper={setWrapperRef}
      wrapper={wrapperRef}
      placement="bottom-end"
      trigger={
        <button
          ref={setTriggerRef}
          className={styles.user}
          onClick={() => {
            if (!show) {
              setShow(true)
            }
          }}
        >
          <img
            src={
              user?.photo ??
              "https://talentindonesia.s3.ap-southeast-1.amazonaws.com/assets/jp/default.png"
            }
            className={styles.avatar}
          />
          <ChevronLeft className={styles.chevron} />
        </button>
      }
    >
      <ul ref={areaRef} className={styles.area}>
        <Menu
          label="Resume"
          icon={<FaceIcon className={styles.icon} />}
          to="/dashboard/resume"
        />
        <li className={styles.divider} />
        <Menu label="Lowongan Disimpan" to="/dashboard/bookmarked" />
        <Menu label="Lamaran" to="/dashboard/lamaran" />
        {/* <Menu
          label="Company profile"
          icon={<BusinessAltIcon className={styles.icon} />}
          to="/company"
          />
          <Menu
          label="Manage PICs"
          icon={<PicAltIcon className={styles.icon} />}
          to="/pic"
        /> */}
        <li className={styles.divider} />
        <Menu label="Logout" button cta={handleSignOut} />
      </ul>
    </PopperWrapper>
  )
}

export default AvatarNavbar
