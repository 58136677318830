import styles from "./PopperWrapper.module.scss"
import React, { useState, forwardRef } from "react"
import cx from "clsx"
import Portal from "@reach/portal"
import { usePopper } from "react-popper"
import { mergeRefs } from "../../utils/mergeRef"

const PopperWrapper = forwardRef(
  (
    {
      show,
      trigger,
      children,
      className,
      placement,
      triggerRef,
      wrapper,
      setWrapper,
      ...restProps
    },
    forwardedRef
  ) => {
    const [arrowElement, setArrowElement] = useState(null)

    const { styles: popperStyle, attributes } = usePopper(triggerRef, wrapper, {
      placement,
      modifiers: [{ name: "arrow", options: { element: arrowElement } }],
    })

    return (
      <>
        {trigger}
        {show && (
          <Portal>
            <div
              ref={mergeRefs(forwardedRef, setWrapper)}
              style={{ zIndex: 999999999, ...popperStyle.popper }}
              {...attributes.popper}
            >
              {children}
              <div ref={setArrowElement} style={popperStyle.arrow} />
            </div>
          </Portal>
        )}
      </>
    )
  }
)

export default PopperWrapper
